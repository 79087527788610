const { useState } = window["React"];

function chat_transfer_team_list(props) {
  const { lang } = props;

  const [selectDefault, setSelectDefault] = useState(false);

  React.useEffect(() => {
    setSelectDefault(
      props &&
        props.business_data &&
        props.business_data.default_status === "reception"
        ? false
        : true
    );
  }, [props.business_data]);

  const handleChangeSelect = () => {
    setSelectDefault(!selectDefault);
    updateReceptionStatus(!selectDefault ? "chatbot" : "reception");
  };

  const updateReceptionStatus = (defaultStatus) => {
    axios({
      method: "post",
      url:
        backend +
        "app/" +
        props.business_data.uid +
        "/" +
        props.business_data.app_id +
        "/settings/reception/default-reception",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        version: main.version,
        appId: props.business_data.app_id,
        defaultStatus: defaultStatus,
      }),
    }).then((res) => {
      if (res && res.data && res.data.result === 0) {
        props.toast.success(lang.__setting_reception_default_successfully);
      } else {
        props.toast.error(lang.__setting_reception_default_failed);
      }
    });
  };
  return (
    <div>
      <div class="sp-row">
        <div class="sp-col">
          <span class="sp-label">{lang.__team_reception_default}</span>
          <p class="sp-text-help" style={{ marginTop: "1%" }}>
            {lang.__team_reception_default_description}
          </p>
        </div>
        <Toggle defaultStatus={selectDefault}>
          <label>
            <input type="checkbox" onChange={handleChangeSelect} />
            <div class="toggle-button cursor-pointer">
              <span></span>
            </div>
          </label>
        </Toggle>
      </div>
    </div>
  );
}

const Toggle = window.styled.div`
    border-radius: 20px;
    text-align: center;
    position: relative;
    width: 56px;
    height: 24px;
    color: #fff;
    margin: 0 0 5px auto;
    background-color: ${(props) =>
      props.defaultStatus ? "#27DB7A" : "#AEBAD0"};
    border: 2px solid ${(props) =>
      props.defaultStatus ? "#27DB7A" : "#AEBAD0"};
    input{
      display: none;
  }
    label {
      font-weight: normal;
    }

    label div{
      display: inline-block;
      background-color: #fff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      ${(props) => (props.defaultStatus ? "right: 0" : "left: 0")};
      cursor: pointer;
      span{
          position: absolute;
          left: ${(props) => (props.defaultStatus ? "-20px" : "23px")};
          white-space: nowrap;
      }
      span::after {
          content: "${(props) => (props.defaultStatus ? "on" : "off")}";
      }
  }
`;
